@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noto Sans";
  font-weight: normal;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Notosans.ttf) format("truetype");
}
body {
  font-family: "Noto Sans", sans-serif !important;
}

* {
  font-family: "Noto Sans", sans-serif !important;
}

.downward {
  color: #cc0605;
}

.upward {
  color: #06ab04;
}

.downward img,
.upward img {
  width: 15px;
}

button {
  border-radius: 0.4rem;
}

.new-added-users {
  color: #48a4da;
}

.rounded-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
}

.para {
  color: #50504f;
  font-size: 0.8rem;
}

.primary-color {
  color: #0040a1 !important;
}

.btn2 {
  border: 2px solid #0040a1 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  color: #0040a1 !important;
  font-weight: bold !important;
}

.btn2 .minus {
  background-color: #0040a1;
}

.btn2.medium {
  padding: 3px 10px !important;
}

.post-img {
  max-width: 70px;
  height: auto;
}

.hover-row {
  cursor: pointer;
}

.hover-row:hover .eye-icon {
  display: block;
}

.eye-icon {
  width: 30px;
  padding: 0 5px;
  display: none;
}

table .icon {
  width: 50px;
}

textarea {
  outline: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

/* textarea:hover {
  border-bottom: 2px solid #000;
} */

textarea:focus {
  border-bottom: 2px solid #0040a1;
}

.MuiInput-underline:before {
  content: "" !important;
}

.search-wrapper {
  background: #f6f6f6;
  border-radius: 10px;
}

.search-wrapper input {
  border: 0 !important;
  outline: none !important;
  background: #f6f6f6;
}

.search-wrapper input::placeholder {
  text-align: left;
  font: normal normal normal 16px/20px "Nato sans";
  letter-spacing: -0.3px;
  color: #6b6a6a;
}

.primary-btn {
  border: none;
  background-color: #e27100;
  color: #0040a1;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

.secondary-btn {
  border: none;
  background-color: #0040a1 !important;
  color: #fff !important;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

button .minus {
  width: 8px;
  height: 2px;
  background-color: #fff;
  margin-right: 8px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form input {
  width: 100%;
  border: none;
  padding: 0.5rem 1.5rem;
  background-color: #fff !important;
  margin-bottom: 2rem;
  outline: none;
}

.auth-container {
  background-color: #ffffff;
  padding: 4rem 0;
  position: relative;
  box-shadow: 2px 6px 20px #dededec7, 0 6px 6px #dededec7;
  border-radius: 1rem;
}

.text-btn {
  color: #636363;
  font-size: 12px;
  margin-top: 1rem;
  cursor: pointer;
}

.text-btn2 {
  margin-left: 5px;
  display: inline-block;
  border-bottom: 1px solid #bec0c7;
  color: #bec0c7;
  line-height: 1;
  font-size: 0.8rem;
  cursor: pointer;
}

.button-1 {
  width: auto;
  min-width: 100px;
  border: 0;
  border-radius: 0;
  background-color: #e27100;
  color: #0040a1;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}

.as_mobile {
  display: none;
}
.as_web {
  display: block;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #0040a1 !important;
}

.custom-tab-btn {
  border: 0 !important;
  color: #bec0c7 !important;
  font-weight: 700;
  cursor: pointer;
}

.custom-tab-btn.active {
  color: #0040a1 !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  /* border-bottom: 3px solid #0040a1 !important; */
}

/* Layout */
.layout {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 4.5rem); */
}

.side-nav {
  width: 320px;

  border-radius: 10px;
  height: 100vh;
  position: sticky;
  top: 0;
}

.page-container {
  width: calc(100% - 320px);

  background-color: #f6f6f6;
}

.header {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}

.navbar_active {
  color: #0040a1;
}

.notification_rounded {
  border-radius: 100%;
}

.header .search-wrapper {
  width: 100%;
  max-width: 500px;
  height: 100%;
}

.body-container {
  height: calc(100vh - 70px);
  padding-top: 48px;
}

.inner-header {
  width: calc(100% - 240px) !important;
  position: fixed !important;
  top: 70px;
}

/* Layout */

/* SideNav */
#sideNav {
  background-color: #fff;
  height: 90%;
  padding: 0 2.5rem;
  color: #6b6a6a;
  border-radius: 10px;
  position: fixed;
  top: 75px;
  width: 320px;
}

#sideNav .profile-info {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  padding: 2rem 0;
  width: 180px;
}

#sideNav .profile-info .title {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

#sideNav .profile-info .sub-title {
  color: #e27100;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

#sideNav .navlist {
  height: calc(100vh - 28vh);
}

#sideNav .navlist ul {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  /* padding-top: 2rem; */
}

#sideNav .navlist ul li {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

/* SideNav */

/* Custom-list */
.custom-list li {
  font-size: 0.8rem;
  color: #636363;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

/* Custom-list */

/* Tables */
.primary-table-theme .head {
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 th {
  position: sticky;
  top: 0px;
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  padding: 0.5rem;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 tr {
  box-shadow: none;
}

.table-theme-1 tr:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.table-theme-1 td {
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff !important;
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
  text-align: left;
}

.table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.custom-table .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee9f9;
  color: #0040a1;
  font-size: 0.8rem;
  z-index: 5;
}

.custom-table .head li {
  padding: 1rem 2rem;
}

#manage-tab .head li:nth-child(1) {
  width: 30%;
}

#manage-tab .head li:nth-child(2) {
  width: 15%;
}

#manage-tab .head li:nth-child(3) {
  width: 15%;
}

#manage-tab .head li:nth-child(4) {
  width: 15%;
}

#manage-tab .head li:nth-child(5) {
  width: 25%;
}

.custom-table .body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-table .custom-row {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: 480px;
  border-bottom: 1px solid #f6f6f6;
}

.custom-table .body .left {
  width: 30%;
  padding: 1rem;
}

.custom-table .body .right {
  width: 70%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-table .body .comment-section {
  height: 70%;
  overflow-y: auto;
}

.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: -8%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #0040a1;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #0040a1;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #0040a1;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}

.custom-table .comment-lists {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #50504f;
  border-top: 0.9px solid #f6f6f6;
  border-bottom: 0.9px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.custom-table .comment-lists li {
  display: flex;
  align-items: center;
}

.custom-table .comment-lists li:nth-child(1) {
  width: 25%;
}

.custom-table .comment-lists li:nth-child(2) {
  width: 23%;
}

.custom-table .comment-lists li:nth-child(3) {
  width: 22%;
}

.custom-table .comment-lists li:nth-child(4) {
  width: 32%;
}

.custom-table .comment-lists li:nth-child(1) img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

/* Tables */

/* Modal */
#custom-modal {
  margin: 0 auto;
  width: 100%;
  border: 0;
  outline: none;
  padding: 2rem 5rem 5rem;
  max-height: 90vh;
  overflow-y: auto;
  border-top: 5px solid #0040a1;
}

#custom-modal .title {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
  margin-bottom: 3rem;
  text-transform: uppercase;
  border-bottom: 2px solid #0040a1;
}

#custom-modal .label,
#custom-modal .radio-label span {
  font-size: 1rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
}

#custom-modal .radio-label {
  margin-right: 2rem;
}

#custom-modal .cross {
  position: relative;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  right: -3rem;
}

#custom-modal .cross::before {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -2px;
  right: 99px;
  position: absolute;
  transform: rotate(134deg) translate(2.5px, -0.5px);
  right: 8px;
}

#custom-modal .cross::after {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -7px;
  right: 19px;
  position: absolute;
  transform: rotate(225deg) translate(-9.5px, 0px);
  right: 16px;
}

#custom-modal .warning-modal .title {
  color: #cb0000;
  border-bottom: 2px solid #cb0000;
  text-align: center;
}

#custom-modal .warning-modal p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

#custom-modal .warning-modal button {
  border-width: 1px !important;
  font-weight: 400 !important;
  padding: 0.3rem 2rem !important;
}

#custom-modal .warning-modal .approve_decline_padding {
  background-color: #cb0000;
  color: #fff !important;
}

#custom-modal .warning-modal .approve_decline_padding .cross-icon::before,
#custom-modal .warning-modal .approve_decline_padding .cross-icon::after {
  background-color: #fff;
}

/* Modal */

.react-confirm-alert-overlay {
  opacity: 0.8 !important;
}

/* Form Styling */
.custom-form-element {
  border: 0 !important;
  border-bottom: 1px solid #f6f6f6 !important;
  border-radius: 0 !important;
}

/* Form Styling */
.approve_decline_padding.danger-bg {
  background-color: #cb0000;
  color: #fff !important;
}

.approve_maintenace_padding.yellow-bg {
  background-color: yellow;
  color: #0040a1 !important;
  font-weight: bold;
}

.approve_maintenace_padding.yellow-bg:hover {
  background-color: yellow !important;
  color: #0040a1 !important;
}

.approve_decline_padding.danger-bg .cross-icon::before {
  background-color: #fff;
}

.approve_decline_padding.danger-bg .cross-icon::after {
  background-color: #fff;
}

.approve_decline_padding.danger-bg:hover {
  color: #cb0000 !important;
}

.approve_decline_padding.danger-bg:hover .cross-icon::before {
  background-color: #cb0000;
}

.approve_decline_padding.danger-bg:hover .cross-icon::after {
  background-color: #cb0000;
}

.approve_decline_padding .cross-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.approve_decline_padding .cross-icon::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}

.approve_decline_padding .cross-icon::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(315deg);
}

.approve_decline_padding.cross-icon {
  position: relative;
}

.MuiButton-outlinedSecondary {
  color: #cb0000 !important;
  border: 1px solid #cb0000 !important;
}

/* request page */
.approval_button_style.mr-10 {
  margin-right: 2rem;
}

.approve_decline_padding.ml-10 {
  margin-left: 2rem;
}

.approval_button_style {
  color: #06ab04 !important;
  border-color: #06ab04 !important;
  border-radius: 0 !important;
}

.approve_decline_padding {
  border-radius: 0 !important;
}

/* request page */

/* event page */
.text_tosmall_10 {
  font-size: 10px;
}

.react-calendar {
  width: 95%;
  background: none;
  line-height: 1.125em;
  box-shadow: 5px 5px 25px #dededecc;
  border: none;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: block !important;
}

.react-calendar button {
  padding: 15px 0px;
  margin: 15px 0;
  color: #50504f;
}

abbr[title] {
  text-decoration: none;
  color: #0040a1;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  background: #dee9f9;
}

.react-calendar__tile--now,
.react-calendar__tile--active {
  background: none;
}

.react-calendar__tile--now abbr {
  background: #0040a1;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--active abbr {
  background: #e27100;
  color: #0040a1;
  font-weight: bold;
  padding: 10px;
  border-radius: 50%;
}

.cutome_calander_class abbr {
  position: relative;
  padding: 10px;
}

.cutome_calander_class abbr::after {
  content: ".";
  position: absolute;
  bottom: 10%;
  left: 44%;
  color: #0040a1;
  font-weight: bold;
}

.react-calendar__navigation button span {
  color: #0040a1;
  font-weight: bold;
}

.react-calendar__navigation button {
  color: #bec0c7;
}

.react-calendar__month-view__days {
  background-color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}

.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}

.react-calendar__navigation__label {
  width: 10rem;
}

/* event page */

.row-border-bottom {
  width: 80%;
  margin: 2rem auto;
  background-color: #f6f6f6;
  height: 2px;
}

.removed-post.divider {
  position: relative;
}

.removed-post .avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 5px;
}

.removed-post.divider::before {
  content: "";
  position: absolute;
  left: -9%;
  top: 15%;
  display: block;
  width: 2px;
  height: 80%;
  background-color: #f6f6f6;
}

.media-box {
  display: flex;
  justify-content: center;
  color: #bec0c7;
  font-size: 1rem;
  box-shadow: 5px 5px 20px #d4d4d4b3;
  padding: 1rem;
  max-width: 90%;
  cursor: pointer;
}

.media-box img {
  margin-right: 5px;
  max-width: 100%;
}

.floating-box {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #0040a1;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-green {
  background-color: rgb(10, 180, 10);
}

.bg-orange {
  background-color: #ffaa00;
}

.radio-group {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.MuiFormControlLabel-root span {
  width: max-content;
}

.latest-post-card {
  height: 360px;
}

.comment-bg {
  background-color: #f6f6f6;
}

.a {
  fill: #bec0c7;
  transition: all 0.2 ease;
}

.a:hover {
  fill: #0040a1;
}

.comment-section {
  max-height: 300px;
  overflow-y: auto;
}

.comment-section::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.comment-section ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comment-section::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.comment-section ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sticky-sidebar {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.hub-logo {
  max-width: 40px;
}

.addicon {
  max-width: 35px;
  margin-left: 10px;
}

.addtext {
  color: #0040a1;
  cursor: pointer;
  text-transform: uppercase;
}

.flag {
  max-width: 30px;
}

.round-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user_data_admin {
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
}

.ImageShadow {
  box-shadow: 5px 5px 25px #dededecc;
}

.imageMarginTop {
  margin-top: -60px;
}

.partyBoxShadow {
  box-shadow: 5px 5px 25px #eeeeee;
}

.customeCheckbox label .MuiFormControlLabel-label {
  color: #50504f;
}

.customeCheckbox label span span .MuiSvgIcon-root {
  fill: #06ab04;
}

/* otp */
.otpSection input {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 4px;
  margin: 0 1.5rem;
  background-color: #dfe8f7;
}

.otpSection input:first-child {
  margin-left: 0;
}

.otpSection input:focus {
  outline: none;
}

/* otp */

.RotateArrowSign {
  transform: rotate(90deg);
}

/* Editor Start */
.editorSectionStart {
  margin-top: 20px;
  border: 1px solid #bec0c7;
  min-height: 20rem;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.editorClassName {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* Editor Start */

/* changePassword */
.password_inputField_div {
  width: 100%;
}

.password_inputField_div input {
  margin-bottom: 2rem !important;
}

.password_inputField_div p {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

/* changePassword */

.table_column_min_width {
  min-width: 15rem;
}

.cross_icon_margin {
  margin-right: 4rem !important;
}

.bulkUploadOverFlow {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.bulkUploadOverFlow::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  -webkit-overflow-scrolling: auto;
}

.MuiButton-root {
  width: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

/* New Styles Start Here */

.glpLoginBgBanner {
  width: 100%;
  height: 100vh;
  background-image: url("../Assets/images/glp-login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginTopSignUp {
  margin-top: 8rem;
}

.authFormInputStyle {
  height: 35vh;
}

.forSignUpAuthForm {
  height: auto;
}

.authFormInputStyle input {
  width: 100%;
  height: auto;
  background-color: #dfe8f7 !important;
  border-radius: 0.4rem;
}

.countrycode {
  background-color: #dfe8f7 !important;
  height: 40px;
  width: 100px;
  padding: 0 5px;
  border-radius: 0.4rem;
  margin-right: 5px;
}

.countrycode .country-img {
  width: 40px;
  margin-right: 5px;
}

.countrycode .country-img img {
  width: 100%;
}

.forSignUpAuthForm .languageSelect,
.sectorSelectMain .sectorSelect,
.ageandGenderMain .genderSelect {
  width: 100%;
  height: auto;
  background-color: #dfe8f7 !important;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  padding: 0.5rem 1.5rem;
  color: #0040a1 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
}

/* .forSignUpAuthForm::after, */
.sectorSelectMain::after,
.ageandGenderMain::after {
  content: url("../Assets//images/arrow.svg");
  position: absolute;
  right: 2rem;
  width: 1rem !important;
  top: 0.5rem;
}

.genderSelect {
  margin-left: 1rem;
}

.forSignUpAuthForm,
.sectorSelectMain,
.ageandGenderMain {
  position: relative;
}

.authFormInputStyle select:active {
  border: none;
  outline: none;
}

.authFormInputStyle select:focus {
  border: none;
  outline: none;
}

.ageandGenderMain {
  display: flex;
  align-items: center;
}

.authFormInputStyle input::placeholder {
  color: #0040a1 !important;
}

.inactiveGetOtpBtn {
  width: 100%;
  height: auto;
  padding: 0.4rem;
  background: #eaeaea;
  color: #c3c3c3;
}

.ActiveGetOtpBtn {
  width: 100%;
  height: auto;
  padding: 0.4rem;
  background: #0f9d58;
  color: #fff;
}

.signUpBtn {
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #0040a1;
  color: #0040a1;
  font-weight: 600;
  text-transform: uppercase;
}

.signUpBtn:hover {
  border: 1px solid #e27100;
  background-color: #e27100;
  color: #fff;
}

.signUpSectionLogin p {
  margin-bottom: 2rem;
  color: #0040a1;
  font-size: 1rem;
}

.PhoneInputCountry {
  align-items: flex-start;
  margin-top: 1rem;
}

.checkboxsignup {
  display: flex;
  align-items: center;
  flex-direction: row !important;
}

.checkboxsignup {
  color: #0040a1;
}

.termsAndCondition {
  margin-left: -0.5rem;
  font-size: 1rem;
  color: #0040a1;
}

.MuiSvgIcon-root {
  fill: #0040a1 !important;
}

.PhoneInput {
  width: 100%;
}

.SubMenuData {
  padding-top: 1rem !important;
  margin-left: 1rem;
  height: 15rem !important;
}

.fontSizeSideNav {
  font-size: 0.8rem;
  text-transform: capitalize;
}

.filterSection h3 {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 500;
  margin-bottom: 1rem;
}

.filterSection p {
  font-size: 1rem;
  font-weight: 400;
  color: #6b6a6a;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.filterlogo {
  width: 13px !important;
}

/* New Styles End Here */
.savedForum h3 {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 500;
  margin-bottom: 2rem;
}

.rightSideBar {
  /* max-width: 450px; */
  /* width: 450px; */
  width: 340px;
  height: 100vh;
  position: fixed;
  top: 75px;
}

.forumMain {
  width: 100%;
}

/* glp work by himanshu */
.bgtheme {
  background-color: #f6f6f6;
}

.navicon img {
  width: 35px;
  height: 20px;
}

.bgcolor {
  background-color: #f6f6f6;
  height: 30px;
  z-index: 1000;
  position: fixed;
  top: 28px;
}

.mycontainer {
  padding-left: 5rem;
  padding-right: 5rem;
}

.inputsearch {
  position: relative;
}

.inputsearch::before {
  content: url(../Assets/images/icons/search.svg);

  position: absolute;
  top: 5px;
  left: 6px;
  width: 24px;
  height: 24px;
  z-index: 99;
}

#filtersrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#filtersrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#filtersrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0040a1;
}

.FeaturedMain h2 {
  text-align: left;
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #e27100;
}

.cardhead {
  text-align: justify;
  font: normal normal 600 18px/33px "Noto Sans";
  letter-spacing: 0px;
  color: #111111;
  line-height: 28px;
  padding-right: 10px;
  max-width: 550px;
}

.activecardhead {
  font: normal normal 600 18px/33px "Noto Sans";
  color: #111111;
  line-height: 25px;
  text-align: justify;
}

.socialimage img {
  width: 27px;
  height: 27px;
}

.peratextcolor {
  font: normal normal normal 14px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #0040a1;
}

.peratextcolorlink {
  font: normal normal normal 14px/24px "Noto Sans";

  color: #0040a1;
  border-bottom: 1px solid #0040a1;
}

.socialbtn p {
  font: normal normal normal 14px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #6b6a6a;
}

.activesocial {
  text-align: left;
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: 0px;
  color: #0040a1;
}

.active_socialbtn p {
  font: normal normal normal 14px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #6b6a6a;
}

.formdetailstile {
  font: normal normal 600 20px/33px "Noto Sans";
  color: #111111;
  line-height: 25px;
}

.formdetailstags {
  font: normal normal normal 16px/24px Arial;
  letter-spacing: 0px;
  color: #0040a1;
}

.fromcontant {
  font: normal normal normal 14px/18px Arial;
  letter-spacing: -0.1px;
  color: #111111;
  line-height: 20px;
}

.realeted_search {
  max-width: 60px;
}

.realeted_search img {
  width: 100%;
}

.reatedtext p {
  padding: 0;
  line-height: 22px;
}

.commentinput {
  width: 90%;
  background: transparent;
}

.commentinput:focus-visible {
  outline: none;
}

.socialimagedetails {
  width: 100%;
}

.search_input {
  width: 92%;
}

.search_input input {
  border: 0 !important;
  outline: none !important;
}

.search_input input::placeholder {
  text-align: left;
  font: normal normal normal 16px/20px "Noto Sans";
  letter-spacing: -0.3px;
  color: #6b6a6a;
}

.avtar h3 {
  text-align: left;
  font: normal normal bold 18px/20px "Noto Sans";
  letter-spacing: -0.3px;
  color: #0040a1;
}

.cancel-btn {
  border: 2px solid orange;
  padding: 5px 10px;
  /* background-color: #ffdf1b; */
  color: #184183;
  font-weight: 600;
}

.delete-btn {
  border: 1px solid red;
  padding: 5px 10px;
  background-color: red;
  color: #fff;
  font-weight: 600;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  font-size: 14px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
  left: -104px;
  top: 26px;
}

/* comment section */

/* comment section */

.formBackground {
  background: #f6f6f6;
}

.formBackground input {
  outline: none;
}

.aliasname {
  text-align: left;
  font: normal normal bold 18px/20px Arial;
  letter-spacing: -0.3px;
  color: #6b6a6a;
}

.profile {
  width: 1000px;
  height: 100vh;
  position: fixed;
  top: 75px;
}

.profile h1 {
  text-align: left;
  font: normal normal bold 24px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #0040a1;
}

.profilecontainer {
  border: 1px solid #dfdfdf;
  min-height: 700px;
}

.aliasprofile {
  background-color: #f1f9ff;
  height: 700px;
}

.aliasprofile h2 {
  text-align: left;
  font: normal normal bold 20px/20px Arial;
  padding: 0.4rem 0;
  letter-spacing: -0.42px;
  color: #0040a1;
}

.aliasprofile h3 {
  text-align: left;
  font: normal normal bold 18px/20px Arial;
  letter-spacing: -0.33px;
  padding: 0.4rem 0;
  color: #6b6a6a;
}

.aliasprofile h4 {
  text-align: left;
  font: normal normal normal 18px/20px Arial;
  letter-spacing: -0.33px;
  padding: 0.4rem 0;
  color: #6b6a6a;
}

.logout button {
  border: 1px solid #e27100;
  width: 173px;
  height: 40px;
  color: #e27100;
}

.contact-admin button {
  background: #0040a1;
  border: 1px solid #0040a1;
  width: 173px;
  height: 40px;
  color: #fff;
}

.profiledetails button {
  background-color: #f1f1f1;
  color: #0040a1;
  font-weight: bold;
  border-radius: 0px;
}

.activebtn {
  background: #fff !important;
}

.profilecontainer {
  background-color: #fff;
}

.user-profile h2 {
  text-align: left;
  font: normal normal bold 22px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #e27100;
}

.fromBg {
  background: #f6f6f6;
  padding: 10px 0;
  border-radius: 10px;
}

.fromBg input {
  background: transparent;
  outline: none;
}

.fromBg input::placeholder {
  font: normal normal normal 16px/20px Arial;
  letter-spacing: -0.27px;
  color: #111111;
}

.submit-btn {
  background-color: #0040a1;
  padding: 8px 30px;

  font: normal normal normal 16px/20px Arial;
  letter-spacing: -0.24px;
  color: #ffffff;
}

.delete {
  padding: 8px 30px;
  border: 1px solid #b00020;
  font: normal normal normal 16px/20px Arial;
  letter-spacing: -0.24px;
  color: #b00020;
}

.accordion-button {
  background: #f6f6f6;
  color: #0040a1;
  font-weight: 600;
}

.accordion-body {
  background: #f6f6f6;
}

.accordion-button:not(.collapsed) {
  background: #f6f6f6;
  color: #0040a1;
}

.accordion-button:focus {
  background: #f6f6f6;
  box-shadow: 0px 0px 3px #bcbcbc;
  border: 1px solid #f6f6f6;
}

.legmainheader {
  align-items: center;
}

.legmainheader h1 {
  font: normal normal bold 24px/20px Raleway;
  letter-spacing: -0.42px;
  color: #0040a1;
}

.legmainheader span {
  font: normal normal normal 14px/6px Arial MT;
  letter-spacing: 0.13px;
  color: #e27100;
  text-decoration: underline;
}

.resourcetext {
  font-size: 11px;
  font-weight: 500;
  width: 65%;
}

.downloadpdf {
  width: 20px;
  height: 20px;
}

.newsheading {
  text-align: left;
  font: normal normal bold 24px/20px Raleway;
  letter-spacing: -0.42px;
  color: #0040a1;
}

.news {
  text-align: left;
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #e27100;
}

.discriptionnews {
  text-align: left;
  font: normal normal 600 16px/26px arial;
  letter-spacing: 0px;
  color: #111111;
}

.moderator {
  background-color: #e27100;
  padding: 2px 6px;
  border-radius: 12px;
  color: #fff;
}

.filterContent {
  font-size: 14px !important;
  line-height: 18px;
}

.eventdate h3 {
  text-align: center;
  font: normal normal 56px/20px "Noto Sans";
  letter-spacing: -0.84px;
  color: #e27100;
  line-height: 50px;
}

.eventdetails h3 {
  text-align: left;
  font: normal normal bold 20px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #6b6a6a;
  line-height: 26px;
}

.eventdetails p {
  font: normal normal normal 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.eventdetails span {
  text-align: left;
  font: normal normal normal 12px/20px Arial;
  letter-spacing: -0.21px;
  color: #0040a1;
  cursor: pointer;
}

/* calender */

.react-calendar {
  background: none !important;
  box-shadow: none !important;
}

.react-calendar__month-view__weekdays {
  background: none !important;
}

.react-calendar button {
  margin: 0 !important;
}

.react-calendar__tile--active abbr {
  background: none;
  /* border: 1px solid #e27100 !important; */
  padding: 6px 5px !important;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.react-calendar__tile--now abbr {
  background: #e27100;
  padding: 6px 5px !important;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.cutome_calander_class abbr {
  border: 1px solid #e27100;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  padding: 6px 5px !important;
  /* border-radius: 50%; */
  display: inline-block;
  /* width: 30px;
  height: 30px; */
}

.cutome_calander_class abbr::after {
  content: "";
}

/* story  details */
.page-story {
  position: relative;
}

.page-story p {
  line-height: 1.5 !important;
  position: absolute;
  bottom: 0;
  padding: 10px 10px;
  font: normal normal 600 16px/33px "Noto Sans";
  letter-spacing: 0px;
  color: #ffffff;
}

.storyMains h3 {
  text-align: left;
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #0040a1;
}

.storyFontsize {
  font-size: 14px;
}

.addgalleryheading {
  text-align: left;
  font: normal normal bold 20px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #0040a1;
}

.titlemonth {
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #0040a1;
  padding: 16px 0px;
}

.noevent {
  font: normal normal bold 20px/20px "Noto Sans";
  color: #000;
}

.postbtn {
  margin-top: 20px;
  font: normal normal bold 16px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #0f9d58;

  width: 148px;
  height: 40px;
}

.textareabtn {
  outline: none;
}

.css-1s2u09g-control {
  background: none !important;
  border-color: none;
  border-width: 0px !important;
}

.getHelp {
  height: 600px;
}

.gethelimage {
  width: 292px;
  height: 286px;
}

.writeToUs {
  text-align: center;
  font: normal normal bold 16px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #ffff;
  width: 183px;
  height: 48px;
  background-color: #006edb;
}

.showDirectory {
  width: 183px;
  height: 48px;
  background-color: #e27100;
  font: normal normal bold 16px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #fff;
}

.gethelptext {
  text-align: center;
  font: normal normal 22px/26px "Noto Sans";
  letter-spacing: 0px;
  color: #111111;
}

.directorytitle {
  text-align: left;
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: #0040a1;
}

.directorysubtitle h2 {
  font: normal normal bold 20px/20px "Noto Sans";
  letter-spacing: -0.42px;
  color: gray;
}

.organisation h3 {
  font: normal normal bold 20px/28px "Noto Sans";
  letter-spacing: 0px;
  color: #0040a1;
}

.organisation span {
  font: normal normal normal 12px/18px "Noto Sans";
  letter-spacing: 0px;
  color: #111111;
}

.mssagechatconatiner {
  height: 65vh;
}

.chatcontainer {
  margin-top: 15px;
  height: 75vh;
  background-color: #ffff;
}

.chatbtn button {
  width: 133px;
  height: 48px;
  font: normal normal bold 16px/24px "Noto Sans";
  letter-spacing: 0px;
  color: #ffffff;
  background: #006edb;
}

.EmojiPickerReact {
  top: -575px;
}

.chat-messages {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  height: 60vh;
}

.chat-messages::-webkit-scrollbar {
  width: 0.2rem;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ffffff39;
  width: 0.1rem;
  border-radius: 1rem;
}

.message {
  display: flex;
  align-items: center;
}

.message .content {
  max-width: 60%;
  overflow-wrap: break-word;
  padding: 0.8rem;
  font-size: 0.8rem;
  /* border-radius: 1rem; */
  color: #d1d1d1;
}

.sended {
  justify-content: flex-end;
}

.sended .content {
  background-color: #f1f9ff;
  border-top-left-radius: 21px;
  color: #006edb;
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
}

.recieved {
  justify-content: flex-start;
}

.recieved .content {
  background-color: #006edb;

  border-top-right-radius: 21px;
  color: #ffff;
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
}

.greybtn {
  background: #6b6a6a !important;
  padding: 8px 30px;

  font: normal normal normal 16px/20px Arial;
  letter-spacing: -0.24px;
  color: #ffffff;
}

.avatarBg:hover {
  background: rgb(217, 242, 255);
}

.verifybackBtn {
  border: 2px solid gray !important;
  color: gray;
  width: 120px;
  height: 40px;
}

.verifysend {
  border: 2px solid rgb(74, 194, 74) !important;
  background: rgb(74, 194, 74);
  color: #fff;
  width: 120px;
  height: 40px;
}

.searchtabscreen {
  background: transparent;
  border: 1px solid grey;
  border-radius: 10px;
  /* width: 40%; */
}

.mobilesearch {
  display: none;
}

.filterSection p:hover {
  color: #0040a1;
  cursor: pointer;
}

.Navbar_Responisive {
  display: none;
}

.navbarres {
  display: none;
}

.mobilelogo {
  display: none;
}

.suggest {
  color: #e27100;
}

.suggestform input {
  border: 2px solid #0040a1;
  width: 100%;
  padding: 5px 5px;
  border-radius: 5px;
}

.suggestarea {
  width: 100%;
  border: 2px solid #0040a1;
  padding: 5px 5px;
  border-radius: 5px;
}

.btnsuggest button {
  border: 2px solid #0040a1;
  padding: 5px 10px;
  background-color: #0040a1;
  color: #fff;
}

.languageselect {
  background-color: transparent !important;
}

.languageselect:focus-visible {
  outline: none;
}

.blockbtn {
  width: 173px;
  height: 40px;
  color: #fff;
  background-color: red;
  font-weight: 500;
}

.report {
  border: 1px solid #e27100;
  width: 173px;
  height: 40px;
  color: #e27100;
}

.yesbtn {
  width: 80px;
  height: 40px;
  color: #fff;
  background-color: red;
  font-weight: 500;
}

.block {
  font: normal normal bold 18px/24px "Noto Sans";
}

.activefilter {
  color: #0040a1 !important;
}

.video_thumb {
  position: relative;
}

.video_thumb p {
  position: absolute;
  bottom: 0;
  right: 2px;
  color: #fff;
  font-size: 10px;
}

.playicon {
  position: absolute;
  width: 25px;
  top: 35%;
  left: 40%;
}

.help-event {
  background: #dee9f9;
  border-radius: 10px;
}

.help-event h4 {
  font: normal normal bold 18px/24px "Noto Sans";
  color: #0040a1;
  margin-bottom: 10px;
}

.help-event p {
  font: normal normal normal 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.event-details {
  background: #dee9f9;
  border-radius: 10px;
}

.event-details h3 {
  font: normal normal bold 18px/24px "Noto Sans";
  color: #0040a1;
  margin-bottom: 10px;
}

.event-details p {
  font: normal normal normal 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.event-time {
  padding-bottom: 10px;
  padding-right: 100px;
}

.events-detailslist {
  width: 50%;
}

.event-time h3 {
  font: normal normal bold 18px/24px "Noto Sans";
  color: #0040a1;
  margin-bottom: 10px;
}

.event-time p:first-child {
  font: normal normal bold 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.event-time p:nth-child(2) {
  font: normal normal 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.PhoneInputCountry {
  margin-top: 0.4rem !important;
}

.phoneNumberbg {
  padding: 10px;
  border: 2px solid #0040a1;
}

.phoneNumberbg input:focus {
  outline: none;
}

.PhoneInputInput:focus {
  outline: none;
}

.phonebutton {
  border: 1px solid #e27100;
  width: 173px;
  height: 40px;
  color: #e27100;
}

.phonebuttons {
  border: 1px solid #6b6a6a;
  width: 173px;
  height: 40px;
  color: #6b6a6a;
}

.error {
  color: red;
}

.DeleteInput {
  width: 100%;
  border: 2px solid #0040a1;
  padding: 5px;
}

.submitbtn {
  border: 1px solid #e27100;
  width: 173px;
  height: 40px;
  color: #e27100;
}

.profile-logo {
  position: relative;
}

.editLogo {
  position: absolute;
  background-color: #fff;
  padding: 7px;
  border-radius: 50%;
  bottom: 5px;
  right: 0px;
}

.term-conditons {
  height: 400px;
  overflow-y: auto;
}

.term-conditons h3 {
  font: normal normal bold 20px/24px "Noto Sans";
  text-align: center;
}

.term-conditons h4 {
  font: normal normal bold 18px/24px "Noto Sans";
  margin-bottom: 10px;
  margin-top: 10px;
}

.term-conditons p {
  font: normal normal 14px/20px "Noto Sans";
  letter-spacing: -0.24px;
  color: #6b6a6a;
}

.agescroll {
  width: 100%;
}

.form-social {
  height: 200px;
  border-radius: 5px;
}

.form-image {
  position: relative;
}

.form-image button {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #fff;
  padding: 8px 12px;
  opacity: 0.8;
}

.form-image button {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #fff;
  padding: 8px 12px;
  opacity: 0.8;
}

.noresource {
  font: normal normal bold 20px/24px "Noto Sans";
}

.EventImage {
  width: 200px;
  height: 100px;
}

.EventImage img {
  width: 200px;
  height: 100px;
  border-radius: 15px;
}

.event-detail {
  max-width: 500px;
}

.Newsimage {
  width: 100px;
  height: 100px;
}

.Newsimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.eventdate {
  width: 150px;
}

.event-details {
  /* width: 500px; */
}

.eventsection {
  width: 100%;
}

.featurestoryImage {
  width: 100%;
}

.storytranslatebtn {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #000;
  opacity: 0.3;
  padding: 8px 10px;
  color: #fff;
}
.storytranslatebtn-v2 {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.5rem;
}
.playiconContainer {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6a6a6a;
  border-radius: 1rem;
}
.storytranslatebtn-v2 button {
  background-color: #000;
  opacity: 0.3;
  padding: 8px 10px;
  color: #fff;
}

.inner-story {
  position: relative;
}

.notifyimage {
  position: relative;
}

.notifysocial {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.card-img-bottoms {
  width: 120px;
  border-radius: 10px;
}

.rightSideBar {
  display: block;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1450px !important;
  }
}

@media (max-width: 1340px) {
  .As_gh_profile {
    width: calc(100vh-40px) !important;
  }
  .profile {
    width: 750px;
  }
  .AS_UserProfile {
    width: auto !important;
  }
}

@media (max-width: 1280px) and (min-width: 835px) {
  .AS_profife_Button {
    width: 100% !important;
    height: 100% !important;
    padding: 10px;
  }
  .container {
    max-width: 1160px !important;
  }

  .eventdate h3 {
    font-size: 40px;
  }

  .eventdate h6 {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .side-nav {
    width: 315px;
  }

  #sideNav {
    padding: 0 2rem;
    width: 315px;
  }

  .page-container {
    width: calc(100% - 315px);
  }

  .rightSideBar {
    width: 300px;
  }

  .search-wrapper input {
    width: 86%;
  }
}

@media (max-width: 1140px) {
  .As_gh_profile {
    width: 734px !important;
  }
  .profile {
    width: 671px;
  }
}

@media (max-width: 1040px) {
  .chatbtn .as_web {
    width: 84px;
  }
  .AS_gh_input {
    width: 100% !important;
  }
  .profile {
    width: 569px;
  }
}

@media (max-width: 1078px) {
  .side-nav {
    width: 284px;
  }

  #sideNav {
    padding: 0 1rem;
    width: 284px;
  }

  .page-container {
    width: calc(100% - 284px);
  }
}

@media (max-width: 1048px) {
  .rightSideBar {
    display: none;
  }

  .container {
    max-width: 840px !important;
  }

  .col-md-7 {
    width: 100%;
  }

  .col-md-5 {
    display: none;
  }

  .leftbar {
    width: 100% !important;
  }

  .rightbar {
    display: none;
  }

  .toolmainbar {
    width: 100%;
  }

  .toolsidebar {
    display: none !important;
  }

  /* .financial_mainbar{
      width: 100% !important;
    }
    .financial_sidebar{
      display: none !important;
    } */
  .legalmain {
    width: 100%;
  }

  .legalsidebar {
    display: none;
  }

  .localmain {
    width: 100%;
  }

  .localsidebar {
    display: none;
  }

  .occupationmain {
    width: 100%;
  }

  .occupationside {
    display: none;
  }

  .skillmain {
    width: 100%;
  }

  .skillsidebar {
    display: none;
  }

  .mobilesearch {
    display: block;
  }

  .forummainInnerpage {
    width: 100%;
  }

  .forummainSidebar {
    display: none;
  }

  .eventresponsivemain {
    width: 100%;
  }

  .eventsidemain {
    display: none;
  }

  .mobileresposiveauth {
  }

  .glpLoginBgBanner {
    display: none;
  }

  .mobileLoginscreen {
    height: 100vh;
  }

  .auth-container {
    width: 90%;
  }

  .mobileformscreen {
    width: 85%;
  }

  .mobilescreentitle {
    text-align: center;
  }

  .mobilelogo {
    display: block;
  }
}

@media (max-width: 840px) {
  .container {
    max-width: 768px !important;
  }
  .AS_Profile {
    width: auto !important;
  }
  .side-nav {
    display: none;
  }
  .AS_gh_inputdiv {
    flex-direction: initial;
  }
  .page-container {
    width: 100%;
  }
  .As_gh_profile {
    width: 100% !important;
  }

  .profile {
    width: 740px;
  }

  .mobilehidebg {
    display: none;
  }

  .bgcolor {
    display: none;
  }

  .tablebar {
    position: sticky !important;
    top: 0px;
    width: 100% !important;
  }

  /*============== mobile respoinsieve ============*/

  .Navbar_Responisive {
    margin-bottom: 20px;
    display: block;
  }

  .navicon {
    width: 1.125em;
    height: 0.125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;
    content: "";
    background: #dcdf2f;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }

  .NavBarResponsive_bg {
    background-color: #184183;
  }

  .Avtar_socialpost {
    height: 3.4rem;
    width: 60px;
  }

  .memberAvtar img {
    max-width: 70px;
    height: 70px;
  }

  .memberAvtar {
    margin-top: -2rem;
  }

  .NavBarResponsive_bg {
    padding: 10px 0;
  }

  .memberProfileborder {
    display: none;
  }

  .locked {
    margin-top: 100px;
  }

  .boxmodal {
    left: 50% !important;
    padding: 30px !important;
    width: 458px !important;
  }

  .loction {
    width: 365px;
    background-color: #eeeff4;
    padding: 10px 10px;
  }

  .navbarres {
    display: block;
  }

  .TagleBar:focus {
    outline: none !important;
    box-shadow: none;
  }

  .drodownnav {
    padding-left: 0;
    background: none !important;
  }

  .dropdown-menu {
    background: transparent;
    border: none;
    color: #fff;
  }

  .dropdown-item {
    color: rgba(255, 255, 255, 0.55);
  }

  .cornerImg {
    display: none;
  }

  .navbarBg {
    background-color: #0040a1;
    z-index: 999;
  }

  .profilecontainer {
    flex-direction: column;
  }

  .aliasprofile {
    width: 100% !important;
    height: auto;
    padding-bottom: 25px;
  }

  .profilesidebar {
    width: 100%;
  }

  .profilemainbar {
    width: 100%;
  }

  .profile {
    position: static;
    top: 0;
    height: auto;
  }

  .profilecontainer {
    padding-bottom: 20px;
  }

  .formlikeres img {
    height: 13px;
  }

  .formcommentres img {
    height: 13px;
  }

  .formtimeres img {
    height: 13px;
  }
}

@media (max-width: 670px) {
  .profile {
    width: auto;
  }

  .page-story .storyimage {
    width: 100%;
  }

  .commentsectionresponive {
    width: 100%;
  }
}

@media (max-width: 560px) {
  /* .profile {
    width: 423px;
  } */
  .AS_Event {
    display: block;
  }
  .search-wrapper {
    width: 85%;
  }
  .As_mobile_calender {
    display: none;
  }
  .event-time {
    padding-right: 0px;
  }
}

@media (max-width: 487px) {
  .activecardhead {
    font: normal normal 600 14px/33px "Noto Sans";
  }

  .formdetailstags {
    font: normal normal normal 14px/24px Arial;
  }
}

/* Additional Css  for GLP Anubhav */

.As_gh_chat {
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .AS_mobile_button {
    width: 38px !important;
    height: 40px !important;
  }
  .AS_mobile_button img {
    width: 100%;
    padding: 5px;
    padding-left: 6px;
  }
  .AS_Report {
    display: block !important;
    margin-bottom: 10px !important;
  }
  /* .row{
    width: fit-content;
  } */
  .flex-wrap {
    flex-wrap: nowrap !important;
  }
  .as_mobile {
    display: block;
  }
  .as_web {
    display: none;
  }
  .profile {
    width: 360px;
  }
  .As_help_button {
    display: block !important;
    text-align: center;
  }
  .showDirectory {
    margin: 0;
  }
  .writeToUs {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .AS_NF_tabbar {
    width: 370px;
  }
}
@media (max-width: 425px) {
  .EventImage {
    width: auto;
    height: auto;
  }
  .row {
    width: unset;
  }
  .AS_block_user {
    display: block !important;
  }
}

@media (max-width: 380px) {
  .AS_NF_tabbar {
    width: 280px;
  }
  .profile {
    width: 275px;
  }
  .formcontainer {
    padding-left: 4px;
    padding-right: 4px;
  }

  .search-wrapper {
    width: 80%;
  }

  .formlikeres span {
    font-size: 11px;
  }

  .formcommentres span {
    font-size: 11px;
  }

  .formtimeres span {
    font-size: 11px;
  }

  .cardhead {
    font-size: 14px;
  }

  .formdetailstags {
    font-size: 14px;
  }

  .translateres {
    font-size: 14px;
  }

  .commentsectionresponive {
    width: 100%;
  }

  .gallerylikeres {
    height: 10px;
  }

  .gallerycommentres {
    height: 10px;
  }

  .gallerytimeres {
    height: 10px;
  }

  .likegallerytext span {
    font-size: 11px;
  }

  .commentgallerytextres span {
    font-size: 11px;
  }

  .timegallerytext span {
    font-size: 11px !important;
  }
}

/* responsive */

/* privacy css start */
.privacyPolicyContent h4 {
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.privacyPolicyContent ul {
  list-style: disc;
}

.privacyPolicyContent ul li {
  margin-left: 2rem;
}

.privacyPolicyContent ul li span {
  font-weight: 600;
}

.privacyPolicyContent ol {
  list-style: decimal;
}

.privacyPolicyContent ol li {
  margin-left: 2rem;
}

.privacyPolicyContent p a {
  color: #184183;
  background-color: yellow;
}

.loginPrivacyPolicy {
  text-align: center;
  margin-top: 1rem;
}

.loginPrivacyPolicy a {
  color: #0040a1;
}

.backToLogin a {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

/* privacy css end */

/* nopost */
.nopost {
  font-size: 30px;
}

.ux-btn-group input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.1em;
  font-family: "Arial";
}

.ux-btn-group input[type="checkbox"] {
  display: none;
}

.ux-btn-group input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  height: 1.3rem;
  width: 1.3rem;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  margin-right: 0.5em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

.ux-btn-group input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

.ux-btn-group input[type="checkbox"]:checked + label:before {
  background-color: #0040a1;
  border-color: #0040a1;
  color: #fff;
}

.ux-btn-group input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.loaderfixed {
  height: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullname::after {
  content: url("") !important;
}

/* tabs */
.tab {
  position: relative;
}

.tablebar {
  width: 620px;
  position: fixed;
  top: 57px;
  background: #fff;
  padding-top: 20px;
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around !important;
}

.MuiTab-root {
  min-width: 100px !important;
}

.crossbtn {
  color: red;
  margin-left: 5px;
}

.course-heading {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

.skill-media span {
  font-size: 12px;
}

.green {
  color: #06ab04;
}

.progres {
  color: #e27100;
}

.skill-details h3 {
  font: normal normal 600 20px/33px "Noto Sans";
  color: #111111;
  line-height: 25px;
}

.curriculum {
  background: #f1f1f1;
  margin: 5px 0;
  border-radius: 10px;
  padding: 15px;
}

.curriculum h3 {
  font: normal normal 600 18px/33px "Noto Sans";
  color: #111111;
  line-height: 25px;
}

.verticleline {
  width: 2px;
  height: 20px;
  color: #000;
  background-color: #aaa;
}

.pdf-reader {
  width: 500px;
}

.finish {
  background-color: #0040a1;
  padding: 10px;
  color: #fff;
}

.countries {
  background: #dfe8f7;
  height: 70px;
  padding: 10px;
  border-radius: 4px;
}

.country-icons {
  width: 100px;
  height: 68px;
}

.qus-options {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.optionNumber {
  background: #0040a1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding-top: 5px;
}

.right {
  background-color: #06ab04;
}

.wrong {
  background-color: red;
}

.Nextbtn {
  background-color: #0040a1;
  color: #fff;
  padding: 10px;
}

/* login screen */

/* custom checkbox */

.ux-btn-groups {
  outline: none;
  /* display: flex; */
}

.ux-btn-groups li {
  outline: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.ux-btn-groups input {
  width: 0;
  height: 0;
  cursor: pointer;
}

.ux-btn-groups input.ux-btn-group-item {
  opacity: 0;
  margin-left: 0px;
}

.ux-btn-groups input.ux-btn-group-item:checked + label {
  /* background-image: linear-gradient(180deg, #FFB171 0%, #FF943D 100%); */
  outline: none;
  border: 1px solid #ff943d;
  color: #fff;
  cursor: pointer;
}

.ux-btn-groups input.ux-btn-group-item-correct:checked + label {
  background-color: green !important;
  outline: none;
  border: 1px solid #ff943d;
  color: #fff !important;
  cursor: pointer;
}

.ux-btn-groups input.ux-btn-group-item-wrong:checked + label {
  background-color: red !important;
  outline: none;
  border: 1px solid #ff943d;
  color: #fff !important;
  cursor: pointer;
}

.ux-btn-groups label {
  /* border: 1px solid #808191; */
  border-radius: 24px;
  padding: 17px 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  letter-spacing: 0.04em;
  color: #808191;
  margin: 10px;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
}

.ux-btn-groups label:active {
  outline: none;
  border: 1px solid #ffb171;
  background-image: linear-gradient(
    180deg,
    #ffb171 0%,
    #ff943d 100%
  ) !important;
  color: #fff !important;
}

.ux-btn-groups label:focus {
  outline: none;
}

.testTitle {
  text-align: left;
  font: normal normal bold 28px/20px "Noto Sans";
  letter-spacing: 0px;
  color: #0040a1;
}

.enroll-btn {
  background-color: #0040a1;
  color: #ffff;
  padding: 10px;
}

.event-detail-img {
  width: 24px;
}

/* .activeManth {
  background-color: #FF943D;
  color: #fff !important;
} */

/* custom checkbox */

/* PROGRESSBAR */
.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #184183;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #184183;
  font-weight: 600;
}

/* PROGRESSBAR */

/* Mention details  */

.mention-suggestion {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}

/* Participents div  */

.p-div {
  height: calc(100vh - 181px);
  padding: 2rem;
}
.p-tile {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-tile img {
  height: 100%;
  width: auto;
}
.red-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}

.notificationdot {
  /* position: relative;
  top: 5px;
  left: -6px; */
  height: 1.6rem;
  width: 1.6rem;
  background-color: #06ab04;
  border-radius: 50%;
  color: white;
  /* display: flex; */
  align-items: center;
  text-align: center;
}

.ShareDiv {
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  top: 100%;
  width: 13rem;
  left: 100%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.ShareDiv ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ShareDiv ul li {
  margin: 5px 0;
}

.ShareDiv ul li button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.ShareDiv ul li button:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.ShareDiv ul li button svg {
  margin-right: 10px;
}

.ShareDiv ul li button div {
  display: flex;
  font-size: 14px;
  color: #333;
}

.gallerylikeres {
  margin-right: 8px;
}

